 @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600&display=swap');
.App {
  min-height: 100vh;
}

:root {
  --font-main: 'Roboto SLab', serif;

  --color-main: rgb(253, 252, 220);
  --color-accent-100: rgb(0, 129, 167);
  --color-accent-070: rgba(0, 129, 167, 0.7);
  --color-secondary-100: rgb(0, 175, 185);
  --color-secondary-200: rgb(240, 113, 103);
  --color-secondary-300: rgb(254, 217, 183);
  
  --margin-app: 0 10%;
  --margin-header-sides: 2rem;

  --btn-border-radius: 2px;
  --btn-corner-size: 2rem;
}

/* common */
.parag-mg {
  margin: 0 6rem 0.5rem;
}

.parag-pd {
  padding-top: 6rem;
}

.disp-flex {
  display: flex;
}
.disp-block {
  display: block;
}
.disp-inline {
  display: inline;
}

.row-reverse {
  flex-direction: row-reverse;
}

.wid-100 {
  width: 100%;
}

.text-align-cntr {
  text-align: center;
}

.hover-cursor:hover {
  cursor: pointer;
}

h2 {
  margin-top: 0;
  color: var(--color-secondary-100);
  font-size: 42px;
}
h3 {
  color: var(--color-secondary-200);
  font-size: 30px;
}
/***********/

body {
  background-color: var(--color-main);
  font-family: var(--font-main);
  font-size: 18px;
}

.content-wrapper {
  margin: var(--margin-app);

  min-height: 82vh;
}

header {
  position: sticky;
  top: 0;
  z-index: 999;
  overflow: hidden;

  min-height: 8vh;

  font-size: 24px;

  background-color: var(--color-main);
  border-bottom: black 2px solid;
}

footer {
  position: absolute;
  /* bottom: 0; */
 
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 8vh;

  /* margin: 0.25rem -0% 0.25rem -10%; */

  border-top: black 2px solid;

}
footer div {
  margin: 1rem 0;
  font-size: 12px;
}

.logo {
  margin-left: var(--margin-header-sides);
  img {
    width: 50px;
    height: auto;
    vertical-align: middle;
  }
}

.topnav {
  margin: 0.25rem 0;

  justify-content: space-between;
  align-items: center;
  
  ul li {
    margin: 0 var(--margin-header-sides);
  
    display: inline-block;
  
    text-decoration: none;
    font-size: 18px;

    transition: all 300ms linear;
  }
  ul li:hover {
    color: var(--color-accent-070);
  }
}
.topnav-hamburger {
  display: none;
}
.topnav-hamburger div {
  margin: 5px;

  height: 3px;
  width: 25px;

  background-color: black;

  transition: all 0.5s ease;
}
.active .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.active .line2 {
  opacity: 0;
}
.active .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.skills {
  h4 {
    margin: 0 0.25rem;
  }
  ul {
    margin: 0;
    padding: 0;

    display: block;
    li {
      padding: 0 2%;
      
      display: inline-block;
      
      text-decoration: none;
      font-size: 16px;
    }
    li::before {
      content: url('../img/arrow-right.svg')
    }
  }
}
.skills-list {
  margin: 0.5rem 0;
  padding: 0.5rem 3%;
  
  border: 1px var(--color-secondary-100) solid;
  box-shadow:
    -45px -45px 0 -40px var(--color-secondary-200),
    45px 45px 0 -40px var(--color-secondary-200);

  div {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    h4 {
      flex: 30%;
    }
    ul {
      flex: 60%;
    }
    width: 100%;
  }
}

.work-timeline {
  padding: 0 0.5rem;

  border-left: 3px var(--color-secondary-200) solid;
}
.company-name {
  margin-top: 0.5rem;
}
.exp-right-side {
  margin: 0.5rem 0 0 2rem;
}
.work-date {
  font-size: 16px;
  font-style: italic;
}
.experience-list {
  ul {
    margin-bottom: 0;

    list-style: none;
  }
  li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 30px;
  }
  ul li::before {
    content: url('../img/arrow-right.svg');
    vertical-align: middle;
    position: absolute;
    left: 0px;
    top: 3px;
  }

}

.projects {
  flex-direction: column;
  
  .project-example {
    margin: 2rem 0 1rem;
  
    align-items: center;

    .project-description {
      flex: 30%;
      flex-direction: column;
      
      margin: 0 0 0 5%;
    }
  }
  .project-example.row-reverse {
    .project-description {
      flex: 30%;

      margin: 0 5% 0 0;
    }
  }
  .project-description {
    ul {
      list-style: none;
    }
    ul li::before {
      content: url('../img/arrow-right.svg');
      vertical-align: middle;
    }
  }

  img {
    flex: 60%;

    max-width: 60%;
    height: auto;
  
    border-radius: 3%;
    box-shadow: 3px 3px 5px rgb(193, 193, 193);
  }
  .github-link {
    justify-content: center;
    
    a {
      padding: 0.75rem;
      margin: 5%;

      display: flex;
      align-items: center;
      justify-content: center;

      min-width: 6rem;

      background-color: var(--color-accent-100);
      border-radius: 50px;
      transition: background 300ms ease-in;

      color: white;
      white-space: pre-wrap;
      text-decoration: none;
    }
  }
  .github-link a:hover {
    background-color: var(--color-accent-070);
  }
}


.contact h2 {
  display: flex;
  justify-content: center;
}

.contact-btn {
  margin: 1.5rem auto 4rem;
  padding: 0.75rem 0.25rem;

  position: relative;
  z-index: 1;
  width: 10rem;

  border: var(--btn-border-radius) solid var(--color-accent-100);
  border-radius: 10px;
  background-color: var(--color-main);
  color: var(--color-accent-100);

  transition: 0.3s;

  &::before, &::after {
    content: '';
    position: absolute;
    background: var(--color-main);
    z-index: -1;
    transition: all 300ms;
  }
  &::before {
    width: calc(100% - var(--btn-corner-size));
    height: calc(101% + (var(--btn-border-radius) * 2));
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    height: calc(100% - var(--btn-corner-size));
    width: calc(101% + (var(--btn-border-radius) * 2));
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: white;
    background-color: var(--color-accent-100);
    &::before { width: 0; }
    &::after { height: 0; }
  }
}

/* contact form */
.modal-win.visible {
  position: absolute;
  top: 0;
  left: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  
  z-index: 9999;
  background-color: rgba(91, 92, 92, 0.5);
}
.message-sent-win{
  display: none;
  &.visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 300px;
    height: 200px;

    background-color: var(--color-main);
    border-radius: 10px;

    button {
      justify-content: center;
      align-items: center;

      margin: 2rem 0 0;
      padding: 0.25rem;

      width: 50px;
      height: 25px;

      background-color: var(--color-accent-100);
      border: none;
      border-radius: 10px;
      transition: background 300ms ease-in;

      color: white;
      &:hover {
        background-color: var(--color-accent-070);
      }
    }
  }
}
.contact-form {
  padding-top: 10px;
  margin-bottom: 4rem;
 }
 .contact-input-wrapper {
  justify-content: space-between;
 }
.contact-form input {
  height: 2rem;
  width: 48%;
}
.contact-form textarea {
  width: 99%;
  resize: none;

}

.contact-form textarea,
.contact-form input,
.contact-form .contact-btn-wrapper {
  margin: 2rem 0 0;
}

.contact-form textarea,
.contact-form input {
  border: 2px solid black;
  border-radius: 7px;

  background-color: var(--color-main);

  font-size: 18px;
}
.contact-btn-wrapper {
  justify-content: center;
  margin: 0;
}
.contact-form button {
  margin-top: 0;

  width: 100%;
  height: auto;

  font-size: 24px;
}

/* responsive*/
@media screen and (max-width: 1000px) {
  .project-description .github-link {
    flex-direction: column;
    align-items: center;
  }
  
  .work-timeline {
    flex-direction: column;
  }
}

@media screen and (max-width: 820px) {
  body {
    overflow-x: hidden;
  }  

  .topnav ul {
    position: fixed;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    margin: 0;
    padding: 0;

    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;

    background-color: var(--color-main);

    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    
    li {
      width: 100%;
      height: 100%;

      margin: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    li:hover {
      background-color: var(--color-secondary-300);
      color: var(--color-accent-100);
    }
  }  
  .topnav.visible ul {
    transform: translateX(0%);
  }

  .topnav-hamburger {
    display: block;
    margin-right: var(--margin-header-sides);
  }  

  .skills-list {
    div {
      flex-direction: column;
    }
  }

  .exp-right-side {
    margin: 0;
    
    ul {
      padding: 0;
    }
  }
  .project-example {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    
    img {
      max-width: 100%; 
    }
    .project-description,
    .project-description.row-reverse {
      margin: 0 !important;

      align-items: center;

      width: 100%;
    }
  }

  .parag-mg {
    margin: 4.5rem 5% 0.5rem;
  }

  /* contact form */
  .contact-input-wrapper {
    display: block;
  }
  .contact-form input {
    display: block;
    width: 98%;
  }
  .contact-form textarea {
    width: 98%;
  }
  .contact-form button {
    width: 100%;
  }
}

/* 
@media screen and (max-width: 560px) {
  .App {
    margin: var(--margin-app-mobile);
  }
} */


